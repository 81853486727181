/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MARKET: `市場`,
    TRADE: `交易`,
    SPOT: `現貨交易`,
    OPTIONS: `秒合約交易`,
    CONTRACT: `合約交易`,
    ASSET: `資產`,
    COPY_TRADE: `跟單`,
    LOAN: `貸款`,
    USER_CENTER: `個人中心`,
    SIGNIN: `登入`,
    SIGNUP: `註冊`,
    SLOGAN: `Gemini`
};
