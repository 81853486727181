export default {
  AVATAR_DEFAULT: require('./avatar-default.png'),
  LOGO: require('./logo.png'),
  CUSTOMER: require('./customer.png'),
  
  TRIANGLE_UP: require('./triangle-up.png'),
  TRIANGLE_DOWN: require('./triangle-down.png'),

  ICON_LANGUAGE: require('./icon-language.png'),
  ICON_LANGUAGE_HOVER: require('./icon-language-hover.png'),

  TRADE_SPOT: require('./trade-spot.svg'),
  TRADE_OPTIONS: require('./trade-options.svg'),
  TRADE_CONTRACT: require('./trade-contract.svg'),

  CARET_DOWN: require('./caret-down.png'),
  CARET_DOWN_BLUE: require('./caret-down-blue.png'),
  CARET_UP: require('./caret-up.png'),

  FAVORITES: require('./favorites.png'),
  FAVORITES_ACTIVE: require('./favorites-active.png'),
  FAVORITES_YELLOW: require('./favorites-yellow.png'),

  ICON_PROFIT: require('./icon-profit.png'),
  ICON_LOSS: require('./icon-loss.png'),

  ICON_ASSET_SPOT: require('./icon-asset-spot.png'),
  ICON_ASSET_OPTIONS: require('./icon-asset-options.png'),
  ICON_ASSET_CONTRACT: require('./icon-asset-contract.png'),

  TRANSFER: require('./transfer.png'),

  USER_CENTER_KYC: require('./user-center-kyc.png'),
  USER_CENTER_TEAM: require('./user-center-team.png'),
  USER_CENTER_PWD: require('./user-center-pwd.png'),
  USER_CENTER_TRADE_PWD: require('./user-center-trade-pwd.png'),
  USER_CENTER_CUSTOMER_SERVICE: require('./user-center-customer-service.png'),

  USER_CENTER_STATUS_SUCCESS: require('./user-center-status-success.png'),
  USER_CENTER_STATUS_FAIL: require('./user-center-status-fail.png'),

  QUESTION_MARK: require('./question-mark.png'),

  TEAM_HALF_CIRCLE_LEFT: require('./team_half_circle_left.png'),
  TEAM_HALF_CIRCLE_RIGHT: require('./team_half_circle_right.png'),

  APPLE: require('./apple.svg'),
  ANDROID: require('./android.svg'),

  LANGUAGE: require('./language.png'),
  MORE_BLUE: require('./more-blue.png'),
  MORE_GRAY: require('./more-gray.png'),

  NAV_BACK: require('./nav-back.png'),
  NAV_ADD: require('./nav-add.png'),
  NAV_SHARE: require('./nav-share.png'),
  M_LANGUAGE: require('./m-language.png'),
  M_MORE: require('./m-more.png'),
  
  TAB_HOME_SEL: require('./tab-home-sel.png'),
  TAB_HOME: require('./tab-home.png'),
  TAB_COPY_TRADE_SEL: require('./tab-copy-trade-sel.png'),
  TAB_COPY_TRADE: require('./tab-copy-trade.png'),
  TAB_TRADE_SEL: require('./tab-trade-sel.png'),
  TAB_TRADE: require('./tab-trade.png'),
  TAB_ASSET_SEL: require('./tab-asset-sel.png'),
  TAB_ASSET: require('./tab-asset.png'),
  TAB_USER_CENTER_SEL: require('./tab-user-center-sel.png'),
  TAB_USER_CENTER: require('./tab-user-center.png'),

  HOME_LOAN: require('./home-loan.png'),
  HOME_MARKET: require('./home-market.png'),
  HOME_OPTIONS: require('./home-options.png'),
  HOME_SPOT: require('./home-spot.png'),

  HOOK: require('./hook.png'),

  PEOPLE: require('./people.png'),
  CALENDAR: require('./calendar.png'),

  LEVEL_1: require('./level-1.png'),
  LEVEL_2: require('./level-2.png'),
  LEVEL_3: require('./level-3.png'),
  LEVEL_4: require('./level-4.png'),
  LEVEL_5: require('./level-5.png'),

  COIN_AAVE: require('./coin/aave.svg'),
  COIN_ADA: require('./coin/ada.svg'),
  COIN_AGIX: require('./coin/agix.svg'),
  COIN_AKT: require('./coin/akt.svg'),
  COIN_ALGO: require('./coin/algo.svg'),
  COIN_APE: require('./coin/ape.svg'),
  COIN_APT: require('./coin/apt.svg'),
  COIN_AR: require('./coin/ar.svg'),
  COIN_ARB: require('./coin/arb.svg'),
  COIN_ATOM: require('./coin/atom.svg'),
  COIN_AVAX: require('./coin/avax.svg'),
  COIN_AXL: require('./coin/axl.svg'),
  COIN_AXS: require('./coin/axs.svg'),
  COIN_BCH: require('./coin/bch.svg'),
  COIN_BEAM: require('./coin/beam.svg'),
  COIN_BONK: require('./coin/bonk.svg'),
  COIN_BSV: require('./coin/bsv.svg'),
  COIN_BTC: require('./coin/btc.svg'),
  COIN_BTT: require('./coin/btt.svg'),
  COIN_CAKE: require('./coin/cake.svg'),
  COIN_CFX: require('./coin/cfx.svg'),
  COIN_CHZ: require('./coin/chz.svg'),
  COIN_CRO: require('./coin/cro.svg'),
  COIN_DOGE: require('./coin/doge.svg'),
  COIN_DOT: require('./coin/dot.svg'),
  COIN_DYDX: require('./coin/dydx.svg'),
  COIN_EGLD: require('./coin/egld.svg'),
  COIN_EOS: require('./coin/eos.svg'),
  COIN_ETC: require('./coin/etc.svg'),
  COIN_ETH: require('./coin/eth.svg'),
  COIN_FET: require('./coin/fet.svg'),
  COIN_FIL: require('./coin/fil.svg'),
  COIN_FLOKI: require('./coin/floki.svg'),
  COIN_FLOW: require('./coin/flow.svg'),
  COIN_FLR: require('./coin/flr.svg'),
  COIN_FTM: require('./coin/ftm.svg'),
  COIN_GALA: require('./coin/gala.svg'),
  COIN_GNO: require('./coin/gno.svg'),
  COIN_GRT: require('./coin/grt.svg'),
  COIN_HBAR: require('./coin/hbar.svg'),
  COIN_HNT: require('./coin/hnt.svg'),
  COIN_ICP: require('./coin/icp.svg'),
  COIN_IMX: require('./coin/imx.svg'),
  COIN_INJ: require('./coin/inj.svg'),
  COIN_IOTA: require('./coin/iota.svg'),
  COIN_JASMY: require('./coin/jasmy.svg'),
  COIN_JUP: require('./coin/jup.svg'),
  COIN_KAS: require('./coin/kas.svg'),
  COIN_KAVA: require('./coin/kava.svg'),
  COIN_LDO: require('./coin/ldo.svg'),
  COIN_LINK: require('./coin/link.svg'),
  COIN_LTC: require('./coin/ltc.svg'),
  COIN_MANA: require('./coin/mana.svg'),
  COIN_MATIC: require('./coin/matic.svg'),
  COIN_MINA: require('./coin/mina.svg'),
  COIN_MKR: require('./coin/mkr.svg'),
  COIN_MNT: require('./coin/mnt.svg'),
  COIN_NEAR: require('./coin/near.svg'),
  COIN_NEO: require('./coin/neo.svg'),
  COIN_ONDO: require('./coin/ondo.svg'),
  COIN_OP: require('./coin/op.svg'),
  COIN_PEPE: require('./coin/pepe.svg'),
  COIN_PYTH: require('./coin/pyth.svg'),
  COIN_QNT: require('./coin/qnt.svg'),
  COIN_RNDR: require('./coin/rndr.svg'),
  COIN_RON: require('./coin/ron.svg'),
  COIN_RUNE: require('./coin/rune.svg'),
  COIN_SAND: require('./coin/sand.svg'),
  COIN_SEI: require('./coin/sei.svg'),
  COIN_SHIB: require('./coin/shib.svg'),
  COIN_SNX: require('./coin/snx.svg'),
  COIN_SOL: require('./coin/sol.svg'),
  COIN_STRK: require('./coin/strk.svg'),
  COIN_STX: require('./coin/stx.svg'),
  COIN_SUI: require('./coin/sui.svg'),
  COIN_TAO: require('./coin/tao.svg'),
  COIN_THETA: require('./coin/theta.svg'),
  COIN_TIA: require('./coin/tia.svg'),
  COIN_TON: require('./coin/ton.svg'),
  COIN_TRX: require('./coin/trx.svg'),
  COIN_UNI: require('./coin/uni.svg'),
  COIN_USDT: require('./coin/usdt.svg'),
  COIN_VET: require('./coin/vet.svg'),
  COIN_WIF: require('./coin/wif.svg'),
  COIN_WLD: require('./coin/wld.svg'),
  COIN_XEC: require('./coin/xec.svg'),
  COIN_XLM: require('./coin/xlm.svg'),
  COIN_XMR: require('./coin/xmr.svg'),
  COIN_XRP: require('./coin/xrp.svg'),
  COIN_XTZ: require('./coin/xtz.svg'),

  COIN_GEMINI: require('./coin/gemini.png'),
};
