/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `條款聲明`,
    TERMS: `使用者協定`,
    PRIVACY: `隱私權政策`,
    COOKIE: `Cookie政策`,
    AML: `反洗錢政策`,
    CONDUCT_CODE: `行為準則`,
    CUSTOMER_SERVICE: `客服服務`,
    HELP_CENTER: `幫助中心`,
    FEEDBACK: `意見回饋`,
    CONTACTS: `聯絡我們`,
    CHARGES: `收費標準`,
    SUPPORT: `服務支援`,
    ABOUT_US: `關於我們`,
    ANNOUNCEMENTS: `官方聲明`,
    FAQ: `FAQ`,
    DEPOSIT_WITHDRAW_STATUS: `充提幣狀態`,
    OFFICIAL_VERIFICATION: `官方驗證`,
    LANGUAGES: `語言`,
    SERVICE_EMAIL: `服務信箱`,
    APP_DOWNLOAD: `APP下載`
};
