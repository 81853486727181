/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `首頁`,
    MENU_MARKETS: `市場`,
    MENU_ASSET: `資產`,
    MENU_PLAN: `服務`,
    MENU_USER_CENTER: `個人中心`,
    LOGOUT: `退出`,
    LOGIN: `登入`
};
